<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="orders"
      :item-class="itemBG"
      :loading="isLoadingOrders"
      :server-items-length="ordersMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
    >
      <template v-slot:item.orderStatus="{ item }">
        <select-status-btn :order="item"></select-status-btn>
      </template>
      <template v-slot:item.invoice="{ item }">
        <v-btn
          v-if="canGetInvoice(item)"
          fab
          x-small
          color="blue-grey"
          :loading="is_loading_invoice && index == item.id"
          :disabled="is_loading_invoice && index == item.id"
          @click="getInvoice(item)"
        >
          <v-icon> mdi-download </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.delivery_note="{ item }">
        <v-btn
          v-if="canGetInvoice(item)"
          fab
          x-small
          color="blue-grey"
          :loading="is_loading_dn && index == item.id"
          :disabled="is_loading_dn && index == item.id"
          @click="getDeliveryNote(item)"
        >
          <v-icon> mdi-download </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('order-view')"
          small
          class="mr-2"
          @click="showItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-if="$admin.can('order-update')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('order-delete')"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("orders") }}</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <edit-orders-dialog
            v-if="$admin.can('order-create')"
          ></edit-orders-dialog>
          <delete-orders-dialog
            v-if="$admin.can('order-delete')"
          ></delete-orders-dialog>
          <show-order v-if="$admin.can('order-view')"></show-order>
          <import-CSV v-if="$admin.can('order-create')"></import-CSV>
          <v-btn
            color="primary"
            @click="getCSV(options)"
            :loading="is_loading_csv"
            :disabled="is_loading_csv"
          >
            <v-icon color="white" class="mr-2"> mdi-filter </v-icon>
            {{ $t("export_result_csv") }}
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="options.search"
              outlined
              dense
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" class="pl-0">
            <v-autocomplete
              clearable
              outlined
              dense
              v-model="options.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" class="pl-0">
            <v-autocomplete
              clearable
              outlined
              dense
              v-model="options.order_status_id"
              :items="orderStatuses"
              item-text="name"
              item-value="id"
              :label="$t('status')"
              @focus="getOrderStatuses"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" class="pl-0">
            <v-autocomplete
              clearable
              outlined
              dense
              v-model="options.order_origin_id"
              :items="orderOrigins"
              item-text="name"
              item-value="id"
              :label="$t('order_origin')"
              @focus="getOrderOrigins"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" class="pl-0">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  hide-details
                  v-model="options.created_date_range"
                  :label="$t('created_date_range')"
                  append-icon="mdi-calendar"
                  readonly
                  chips
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.created_date_range"
                range
                no-title
                color="primary"
                class="my-0 py-4"
                header-color="primary"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import { helpers } from "@/mixins/helpers";
import orderService from "@/store/services/order-service.js";

export default {
  mixins: [helpers],

  components: {
    ShowOrder: () => import("./dialogs/ShowOrder.vue"),
    DeleteOrdersDialog: () => import("./dialogs/DeleteOrdersDialog.vue"),
    EditOrdersDialog: () => import("./dialogs/EditOrdersDialog.vue"),
    SelectStatusBtn: () => import("./base/SelectStatusBtn.vue"),
    ImportCSV: () => import("./dialogs/ImportCSV.vue"),
  },

  async mounted() {
    await this.$store.dispatch("orderStatuses/list");
  },

  computed: {
    ...mapGetters({
      isLoadingOrders: "orders/isLoadingOrders",
      orders: "orders/listOrders",
      ordersMeta: "orders/meta",
      orderStatuses: "orderStatuses/listStatuses",
      orderOrigins: "orderOrigins/getOrderOrigins",
      hubs: "hubs/activeHubs",
    }),
  },

  watch: {
    options: {
      handler: debounce(async function (params) {
        this.is_loading_order = true;

        await this.$store.dispatch("orders/list", params).then(() => {
          this.is_loading_order = false;
          if (params.page > this.ordersMeta.lastPage) {
            params.page = 1;
          }
        });
      }, 1000),
      deep: true,
    },

    search_hub: debounce(function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;

      this.$store
        .dispatch("hubs/list", {
          itemsPerPage: 30,
          for: "inbound",
          search,
        })
        .then(() => {
          this.is_loading_hubs = false;
        });
    }, 1000),
  },

  data() {
    return {
      search_hub: "",
      is_loading_hubs: false,
      is_loading_order: false,
      is_loading_csv: false,
      options: {},
      index: 0,
      is_loading_invoice: false,
      is_loading_dn: false,

      headers: [
        {
          text: "ID",
          align: "start",
          value: "tracking_number",
        },
        {
          text: "ID extern",
          align: "start",
          value: "tracking_number_ext",
        },
        {
          text: this.$t("client_name"),
          align: "start",
          value: "client_name",
        },
        {
          text: this.$t("client_phone"),
          align: "start",
          value: "client_phone",
        },
        {
          text: this.$t("status"),
          align: "center",
          value: "orderStatus",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$t("invoice"),
          align: "center",
          value: "invoice",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$t("delivery_note"),
          align: "center",
          value: "delivery_note",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$t("created_at"),
          value: "created_at",
        },
        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
          sortable: false,
          width: "15%",
        },
      ],
    };
  },

  methods: {
    editItem(item) {
      this.$store.dispatch("orders/openEditForm", item);
    },

    showItem(item) {
      this.$store.dispatch("orders/openShowForm", item);
    },

    deleteItem(item) {
      this.$store.dispatch("orders/openDeleteForm", item);
    },

    async getInvoice(item) {
      this.index = item.id;
      this.is_loading_invoice = true;
      await this.$store
        .dispatch("orders/getInvoice", item)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "order_" + item.tracking_number + ".pdf"
          );
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_invoice = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_invoice = false;
        });
    },

    async getDeliveryNote(item) {
      this.index = item.id;
      this.is_loading_dn = true;
      await this.$store
        .dispatch("orders/getDeliveryNote", item)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "order_" + item.tracking_number + ".pdf"
          );
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_dn = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_dn = false;
        });
    },

    async getCSV(option) {
      this.is_loading_csv = true;
      await orderService
        .exportOutboundList(option)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "orders.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_invoice = false;
        });
    },

    itemBG(item) {
      if (item?.order_origin?.name == "API_Express") return "red lighten-5";
      return "";
    },

    canGetInvoice(item) {
      return orderService.canGetInvoice(item);
    },
  },
};
</script>
